import React from "react";
import styled from "styled-components";

const Image = styled.img`
  width: 200px;
  max-height: 40%;
  margin: 0 auto;
  border-radius: 10px;
`;

const Container = styled.div`
  text-align: center;
`;

const Title = styled.p`
  font-weight: bold;
  margin: 0 auto;
  font-size: 24px;
`;

const Subtitle = styled.p`
  font-weight: bold;
  margin: 8px auto;
  line-height: 16px;
  font-size: 16px;
`;

interface IProps {
  title: string;
  subtitle: string;
  image: string;
}

const PhoneWithText = (props: IProps) => {
  const { subtitle, title, image } = props;

  return (
    <Container>
      <Image src={image} />
      {/* <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle> */}
    </Container>
  );
};

export default PhoneWithText;

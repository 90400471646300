import { gql } from "@apollo/client";

const SELECTION = `
  id
  firstName
  lastName
  phone
  email
  photo
`;

const UserFragment = {
  fragment: gql`
      fragment User on User{
          ${SELECTION}
      }
  `,
};
export default UserFragment;

import { FC, useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
`;

const Sub = styled.div`
  font-size: 14px;
  color: #b1adad;
  margin: 0;
  position: absolute;
  left: 8px;
  top: 7px;
`;

const Input = styled.input`
  border: 2px solid #d3d3d3;
  padding: 8px 0;
  text-indent: 16px;
  width: 100%;
  padding-left: 10px;

  ::placeholder {
    color: rgba(26, 26, 26, 0.5);
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :focus {
    outline: none;
    border: 2px solid #d3d3d3;
  }
`;

const PhoneInput: FC<any> = props => {
  return (
    <Container>
      <Sub>+1</Sub>
      <Input {...props} maxLength={10} />
    </Container>
  );
};

export default PhoneInput;

import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { AUTH_ROUTES } from "../../shared/constants/routes";

const AuthRouter = () => {
  return (
    <Routes>
      <Route path={`${AUTH_ROUTES.LOGIN}`} element={<Login />} />
      <Route path={`${AUTH_ROUTES.REGISTER}`} element={<Register />} />
    </Routes>
  );
};

export default AuthRouter;

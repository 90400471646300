import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IStripeProduct } from "../../../../../shared/interfaces/StripeProduct";
import { IDiscountCode } from "../../../../../shared/interfaces/Discount";
import { IStripePrice } from "../../../../../shared/interfaces/StripePrice";

const Container = styled.div`
  width: 98%;
  margin: 26px auto 0;
`;

const ContainerSummary = styled.div`
  margin: 16px 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #d3d3d3;
`;

const SectionTitle = styled.p`
  font-weight: bold;
  margin: 0 0 4px 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Text = styled.p`
  margin: 0 24px;
`;

interface IProps {
  product: IStripeProduct;
  discountCode?: IDiscountCode | null;
  priceIdSelected: string;
}

const OrderSummary = (props: IProps) => {
  const { discountCode, product, priceIdSelected } = props;
  const [price, setPrice] = useState<IStripePrice>();

  const formatString = (value: string | undefined) => {
    if (value) {
      return `$${value.slice(0, value.length - 2)}.${value.slice(value.length - 2)}`;
    }
  };

  const getAmountWithDiscount = () => {
    if (price) {
      if (!discountCode) {
        return price.unit_amount_decimal;
      }
      let value = discountCode.amount_off;
      if (discountCode.percent_off || discountCode.percent_off > 0) {
        value = price.unit_amount - (price.unit_amount * discountCode.percent_off) / 100;
      } else if (discountCode.amount_off || discountCode.amount_off > 0) {
        value = price.unit_amount - discountCode.amount_off;
      }
      return value.toString();
    }
  };

  const _getValue = () => {
    if (price) {
      if (!discountCode) {
        return "";
      }

      if (discountCode.amount_off) {
        return `${discountCode.amount_off}`;
      }
      const value = (price.unit_amount * discountCode.percent_off) / 100;
      return `${value}`;
    }
  };

  useEffect(() => {
    if (product) {
      const price = product.prices.find(price => price.id === priceIdSelected);
      if (price) {
        setPrice(price);
      }
    }
  }, [product]);

  return (
    <Container>
      <SectionTitle>ORDER SUMMARY</SectionTitle>
      <Divider />
      <ContainerSummary>
        <Row>
          <Text>Subtotal</Text>
          <Text>{formatString(price?.unit_amount_decimal)}</Text>
        </Row>
        {discountCode && (
          <Row>
            <Text>Discount</Text>
            <Text>- {formatString(_getValue())}</Text>
          </Row>
        )}
      </ContainerSummary>

      <Divider />

      <Row style={{ marginTop: 16, marginBottom: 16 }}>
        <SectionTitle>Total</SectionTitle>
        <Text>{formatString(getAmountWithDiscount())}</Text>
      </Row>
    </Container>
  );
};

export default OrderSummary;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "./Input";
import { GET_DISCOUNT_CODE } from "../../../../../graphql/discountCode/isEligible.query";
import { useLazyQuery } from "@apollo/client";
import { Button as MuiButton, LinearProgress, Theme, withStyles } from "@material-ui/core";
import { IDiscountCode } from "../../../../../shared/interfaces/Discount";
import { get } from "lodash";
import { IStripeProduct } from "../../../../../shared/interfaces/StripeProduct";
import { getApolloError } from "../../../../../shared/utils/apolloError";
import {
  IGetDiscountCodeRequest,
  IGetDiscountCodeResponse,
} from "../../../../../graphql/discountCode/interface/getDiscountCode.interface";

const Title = styled.p`
  font-weight: bold;
  margin: 0 0 4px 24px;
`;

const Spacer = styled.span`
  width: 10%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MessageError = styled.p`
  color: #f44336;
  font-size: 16px;
`;

const Button = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    color: "white",
    "&:disabled": {
      backgroundColor: theme.palette.grey["300"],
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(MuiButton);

interface IProps {
  onDiscountCode: (discountCode: IDiscountCode | null) => void;
  product: IStripeProduct | null;
}

const DiscountCodeSection = (props: IProps) => {
  const { onDiscountCode, product } = props;
  const [value, setValue] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);

  const [getDiscountCode, { data, loading, error }] = useLazyQuery<
    IGetDiscountCodeResponse,
    IGetDiscountCodeRequest
  >(GET_DISCOUNT_CODE);

  const checkDiscountCode = () => {
    if (value) {
      getDiscountCode({
        variables: {
          input: {
            discountCode: value,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (data) {
      const discountCode = get(data, "GetDiscountCode", null);

      if (discountCode) {
        setShowError(false);
        onDiscountCode(discountCode);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onDiscountCode(null);
      const m = getApolloError(error);
      setShowError(m || "Error applying the coupon");
    }
  }, [error]);

  return (
    <div>
      {loading && <LinearProgress />}
      <Title>DISCOUNTS</Title>
      <Row>
        <Input
          value={value}
          onChange={event => setValue(event.target.value)}
          placeholder={"Add discount code"}
        />
        <Spacer />
        <Button disabled={!value} onClick={() => checkDiscountCode()}>
          APPLY
        </Button>
      </Row>
      {showError && <MessageError>{showError}</MessageError>}
    </div>
  );
};
export default DiscountCodeSection;

import React, { useReducer } from "react";
import { IUser } from "../../interfaces/User";
import {
  removeAccessToken,
  removeRefreshToken,
  removeUser,
  setAccessToken,
  setRefreshToken,
  setUser,
} from "../../utils/localStorage";
import AuthContext from "./AuthContext";
import { AUTH_ACTIONS, EActionsType } from "./Constants";

interface IActions {
  type: EActionsType;
  token: string;
  refreshToken: string;
  user: IUser;
  isLoading: boolean;
}

const AuthProvider = (props: any) => {
  const [state, dispatch] = useReducer(
    (prevState: any, action: IActions) => {
      switch (action.type) {
        case AUTH_ACTIONS.SET_TOKEN:
          setAccessToken(action.token);
          setRefreshToken(action.refreshToken);
          return {
            ...prevState,
            token: action.token,
            refreshToken: action.refreshToken,
            isLoading: false,
          };
        case AUTH_ACTIONS.LOGIN:
          setAccessToken(action.token);
          setRefreshToken(action.refreshToken);
          setUser(action.user);
          return {
            ...prevState,
            token: action.token,
            refreshToken: action.refreshToken,
            user: action.user,
            isLoading: false,
          };
        case AUTH_ACTIONS.SET_USER:
          setUser(action.user);
          return {
            ...prevState,
            user: action.user,
          };
        case AUTH_ACTIONS.LOGOUT:
          removeAccessToken();
          removeRefreshToken();
          removeUser();
          return {
            ...prevState,
            token: null,
            refreshToken: null,
            user: null,
            isLoading: false,
          };
        case AUTH_ACTIONS.SET_LOADING:
          return {
            ...prevState,
            isLoading: action.isLoading,
          };

        default:
          throw new Error();
      }
    },
    {
      isLoading: true,
      token: null,
      refreshToken: null,
      user: null,
    }
  );

  return <AuthContext.Provider value={{ state, dispatch }}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;

import { STORAGE_KEYS } from "../context/AuthContext/Constants";
import { IUser } from "../interfaces/User";

const getLoggedUser = (): IUser | null => {
  const userString = localStorage.getItem(STORAGE_KEYS.USER);
  if (userString) {
    const user = JSON.parse(userString);
    return user;
  }

  return null;
};

const setUser = (user: IUser) => localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user));

const removeUser = () => localStorage.removeItem(STORAGE_KEYS.USER);

const setAccessToken = (token: string) => localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, token);

const getAccessToken = () => localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);

const removeAccessToken = () => localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);

const setRefreshToken = (token: string) => localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, token);

const getRefreshToken = () => localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);

const removeRefreshToken = () => localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);

export {
  getLoggedUser,
  setAccessToken,
  removeAccessToken,
  setRefreshToken,
  removeRefreshToken,
  getAccessToken,
  getRefreshToken,
  setUser,
  removeUser,
};

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../../../../shared/components/Button";
import { CircularProgress, Grid, useTheme } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {
  AUTH_ROUTES,
  ROOT_ROUTES,
  SUBSCRIPTION_ROUTES,
} from "../../../../../../shared/constants/routes";
import { AuthContainer } from "../../../../components/AuthContainer";
import { Underline } from "../../../../components/Form/Underline";
import { ItemForm } from "../../../../components/Form/ItemForm";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  IRegistrationRequest,
  IRegistrationResponse,
} from "../../../../../../graphql/auth/interfaces/registration.interface";
import { REGISTER } from "../../../../../../graphql/auth/register.mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ICheckPhoneNumberRequest,
  ICheckPhoneNumberResponse,
} from "../../../../../../graphql/auth/interfaces/validatePhone.interface";
import { VALIDATE_PHONE_NUMBER } from "../../../../../../graphql/auth/validatePhone.query";
import {
  IValidateCodeRequest,
  IValidateCodeResponse,
} from "../../../../../../graphql/auth/interfaces/validateCode.interface";
import { VALIDATE_CODE } from "../../../../../../graphql/auth/validateCode.mutation";
import { ECodeType } from "../../../../../../graphql/auth/interfaces/codeType.enum";
import { get } from "lodash";
import {
  ICodeRequest,
  ICodeResponse,
} from "../../../../../../graphql/auth/interfaces/generateCode";
import { GENERATE_CODE } from "../../../../../../graphql/auth/generateCode.mutation";
import { AUTH_ACTIONS } from "../../../../../../shared/context/AuthContext/Constants";
import { AuthContext } from "../../../../../../shared/context/AuthContext";
import Input from "../../../../../Subscription/pages/Checkout/components/Input";
import PhoneInput from "../../../../../../shared/components/PhoneInput";

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Required field"),
  lastName: yup.string().required("Required field"),
  phone: yup.string().length(10).required("Required field"),
});

const MessageError = styled.p`
  font-size: 18px;
  color: #f44336;
  margin: 0 auto;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 24px;
  margin: 0;
`;

const Footer = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;

const Resend = styled.p`
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin: 0;
  margin-top: 15px;
`;

const GRID_STYLES = { width: "100%", margin: "0 auto" };

interface IForm {
  firstName: string;
  lastName: string;
  phone: string;
}

const BottomForm = () => {
  const { dispatch: authDispatch } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const [code, setCode] = useState<boolean>(false);
  const [codeInput, setCodeInput] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const [register, { data: dataRegister, error: regError, loading: regLoading }] = useMutation<
    IRegistrationResponse,
    IRegistrationRequest
  >(REGISTER);

  const [GenerateCode, { data: dataCode, loading: loadingCode, error: errorCode }] = useMutation<
    ICodeResponse,
    ICodeRequest
  >(GENERATE_CODE, {
    fetchPolicy: "network-only",
  });

  const [validatePhone, { data: valPhone, error: valPhoneError, loading: valPhoneLoading }] =
    useLazyQuery<ICheckPhoneNumberResponse, ICheckPhoneNumberRequest>(VALIDATE_PHONE_NUMBER, {
      fetchPolicy: "network-only",
    });

  const [validateCode, { data: valData, loading: valLoading, error: valError }] = useMutation<
    IValidateCodeResponse,
    IValidateCodeRequest
  >(VALIDATE_CODE, {
    fetchPolicy: "network-only",
  });

  const loading = regLoading || valPhoneLoading || valLoading;

  const _goToLogin = () => {
    navigate(`/${AUTH_ROUTES.BASE}/${AUTH_ROUTES.LOGIN}`);
  };

  const validateButton = (): boolean => {
    let valid = false;

    if (!code && formik.isValid) {
      valid = true;
    } else if (code && codeInput.length === 6) {
      valid = true;
    }
    return valid;
  };

  const onSubmit = (values: IForm) => {
    const { phone } = values;
    setError(null);

    if (!code) {
      validatePhone({ variables: { phone: `+1${phone}`, type: ECodeType.REGISTER } });
    } else if (code) {
      validateCode({ variables: { input: { phone: `+1${phone}`, code: codeInput } } });
    }
  };

  const sendCode = () => {
    const { phone } = formik.values;
    GenerateCode({ variables: { input: { type: ECodeType.REGISTER, phone: `+1${phone}` } } });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
    },
    onSubmit,
    validationSchema,
    validateOnMount: true,
  });

  useEffect(() => {
    if (valPhone) {
      const valid = get(valPhone, "CheckPhoneNumber.valid", null);
      if (valid) {
        setCode(true);
        sendCode();
      } else {
        setError(
          "An account is already tied to this number. Log in instead or try another number."
        );
      }
    }
  }, [valPhone]);

  useEffect(() => {
    if (valData) {
      const { phone, firstName, lastName } = formik.values;

      const valid = get(valData, "validateCode.valid", null);
      valid
        ? register({
            variables: { input: { phone: `+1${phone}`, firstName, lastName, code: codeInput } },
          })
        : setError("Invalid Code!");
    }
  }, [valData]);

  useEffect(() => {
    if (dataRegister) {
      authDispatch({
        type: AUTH_ACTIONS.LOGIN,
        user: dataRegister.Registration.user,
        token: dataRegister.Registration.access_token,
        refreshToken: dataRegister.Registration.refresh_token,
      });
      navigate(`/${ROOT_ROUTES.SUBSCRIPTION}/${SUBSCRIPTION_ROUTES.SUBSCRIBE}`);
    }
  }, [dataRegister]);

  return (
    <AuthContainer style={{ backgroundColor: theme.palette.primary.main }}>
      {loading && <CircularProgress color={"secondary"} />}

      <Title>Sign up for Pixie</Title>

      <form onSubmit={formik.handleSubmit} style={{ marginTop: 16 }}>
        <Grid container spacing={2} justify="center" alignItems="stretch" direction={"column"}>
          {error && (
            <Grid>
              <MessageError>{error}</MessageError>
            </Grid>
          )}
          {!code && (
            <>
              <Grid item xs={12} md={4} style={GRID_STYLES}>
                <Input
                  style={{ width: "97%" }}
                  name={"firstName"}
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  placeholder={"FIRST NAME"}
                />
              </Grid>
              <Grid item xs={12} md={4} style={GRID_STYLES}>
                <Input
                  style={{ width: "97%" }}
                  name={"lastName"}
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  placeholder={"LAST NAME"}
                />
              </Grid>
              <Grid item xs={12} md={4} style={GRID_STYLES}>
                <PhoneInput
                  style={{ width: "95%" }}
                  name={"phone"}
                  type={"phone"}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  placeholder={"PHONE"}
                />
              </Grid>
            </>
          )}

          {code && (
            <div style={{ marginTop: 16 }}>
              <Grid item xs={12} md={4} style={GRID_STYLES}>
                <Input
                  style={{ width: "97%" }}
                  name={"code"}
                  onChange={e => setCodeInput(e.target.value.toString())}
                  value={codeInput}
                  placeholder={"CODE"}
                />
              </Grid>
              <Resend onClick={() => sendCode()}>Resend code</Resend>
            </div>
          )}

          <Grid item xs={12} md={4} style={GRID_STYLES}>
            <Button type={"submit"} disabled={!validateButton() || loading}>
              {code ? "SIGN UP" : "NEXT"}
            </Button>
          </Grid>
        </Grid>

        <ItemForm>
          <Footer>
            Already have an account? <Underline onClick={_goToLogin}>LOGIN</Underline>
          </Footer>
        </ItemForm>
      </form>
    </AuthContainer>
  );
};
export default BottomForm;

import { gql } from "@apollo/client";
import UserFragment from "../fragments/User.fragment";

export const IS_VALID_WEB_TOKEN = gql`
  mutation IsValidWebToken($input: IsValidWebTokenInput!) {
    IsValidWebToken(input: $input) {
      access_token
      refresh_token
      user {
        ...User
      }
    }
  }
  ${UserFragment.fragment}
`;

import { gql } from "@apollo/client";
import UserFragment from "../fragments/User.fragment";

export const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    Login(input: $input) {
      access_token
      refresh_token
      user {
        ...User
      }
    }
  }
  ${UserFragment.fragment}
`;

export default LOGIN;

import { gql } from "@apollo/client";

const selection = `
id
active
caption
description
name
url
livemode`;

const StripeProductFragment = {
  fragment: gql`
    fragment StripeProduct on StripeProduct {
      ${selection}
    }
  `,
};
export default StripeProductFragment;

import { gql } from "@apollo/client";

const selection = `
  id
  active
  currency
  nickname
  livemode
  unit_amount
  unit_amount_decimal`;

const StripePriceFragment = {
  fragment: gql`
      fragment StripePrice on StripePrice{
          ${selection}
         
      }
  `,
};
export default StripePriceFragment;

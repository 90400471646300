import { gql } from "@apollo/client";
import UserFragment from "./User.fragment";

const selection = `
id
subscriptionId
paymentMethodId
customerId
unsubscribeDate
createdAt
updatedAt`;

const StripeSubscriptionFragment = {
  fragment: gql`
    fragment StripeSubscription on StripeSubscription {
      ${selection}
      user {
        ...User
      }
    }
    ${UserFragment.fragment}
  `,
};
export default StripeSubscriptionFragment;

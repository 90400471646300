import React from "react";
import styled from "styled-components";
import { Checkbox, useTheme, withStyles } from "@material-ui/core";
import { IStripePrice } from "../../../../shared/interfaces/StripePrice";
import { isAnulPrice } from "../../../../shared/utils/prices";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 60%;
  margin: 20px auto 20px auto;
  background-color: ${(props: IContainerProps) => props.color || "#FFFFFF"};
  padding: 8px;
  border: ${(props: IContainerProps) => (props.border ? "3px solid #E0E0E0" : "none")};

  :hover {
    cursor: pointer;
  }
`;

const TextContainer = styled.div`
  margin-top: 6px;
`;

const Title = styled.p`
  font-weight: bolder;
  font-size: 18px;
  margin: 0;
`;

const WhiteBackgroundCheckbox = withStyles(theme => ({
  root: {
    color: (props: any) => (props.color === "default" ? "white" : props.color),
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
  checked: {},
}))(Checkbox);

interface IProps {
  price: IStripePrice;
  selected: boolean;
  onSelectItem: () => void;
}

interface IContainerProps {
  color?: string;
  border?: boolean;
}

const Product = (props: IProps) => {
  const { price, onSelectItem, selected } = props;
  const theme = useTheme();

  const _getColor = () => {
    if (price) {
      return isAnulPrice(price.unit_amount.toString()) ? theme.palette.primary.main : "#BDBDBD";
    }
  };

  return (
    <Container color={_getColor()} border={false} onClick={onSelectItem}>
      <div>
        <WhiteBackgroundCheckbox
          checked={selected}
          onChange={onSelectItem}
          color={/* id === 3 ? "primary" :  */ "default"}
        />
      </div>
      <TextContainer>
        <Title>{price.nickname}</Title>
      </TextContainer>
    </Container>
  );
};

export default Product;

import { gql } from "@apollo/client";
import DiscountCodeFragment from "../fragments/DiscountCode.fragment";

export const GET_DISCOUNT_CODE = gql`
  query GetDiscountCode($input: GetDiscountCodeInput!) {
    GetDiscountCode(input: $input) {
      ...DiscountCode
    }
  }
  ${DiscountCodeFragment.fragment}
`;

export default GET_DISCOUNT_CODE;

import React from "react";
import { ApolloProvider } from "@apollo/client";
import { MuiThemeProvider } from "@material-ui/core";
import { AuthProvider } from "./shared/context/AuthContext";
import apolloClient from "./config/graphql.config";
import Theme from "./config/theme.config";
import Root from "./containers/Root";

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <MuiThemeProvider theme={Theme}>
        <AuthProvider>
          <Root />
        </AuthProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
}

export default App;

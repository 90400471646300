import { Navigate, Outlet, Route } from "react-router-dom";
import { getAccessToken } from "../../utils/localStorage";

const PrivateRoute = (props: any) => {
  const token = getAccessToken();

  return token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;

import { gql } from "@apollo/client";
import StripeSubscriptionFragment from "../fragments/StripeSubscription.fragment";

export const CREATE_STRIPE_SUBSCRIPTION = gql`
  mutation createSubscription($input: CreateSubscriptionInput!) {
    createSubscription(input: $input) {
      ...StripeSubscription
    }
  }
  ${StripeSubscriptionFragment.fragment}
`;

export default CREATE_STRIPE_SUBSCRIPTION;

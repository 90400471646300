import * as yup from 'yup';


export const validateSchema = yup.object().shape({
  cc_complete: yup.boolean().oneOf([true], 'Credit card should be valid'),
  email: yup.string().email().required('Required'),
  aptSuite: yup.string(),
  billingAddress: yup.string().required('Required'),
  city: yup.string().required('Required'),
  firstname: yup.string().required('Required'),
  lastname: yup.string().required('Required'),
  zipCode: yup.string().required('Required'),
  state: yup.string().required('Required'),
  phoneNumber: yup.string().required('Required'),
});
import React from "react";
import { Route, Routes } from "react-router-dom";
import Subscribe from "./pages/Subscribe";
import { SUBSCRIPTION_ROUTES } from "../../shared/constants/routes";
import Checkout from "./pages/Checkout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Success from "./pages/Success";
import PrivateRoute from "../../shared/components/PrivateRoute";

const STRIPE_PROMISE = loadStripe(String(process.env.REACT_APP_STRIPE));

const SubscriptionRouter = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path={`${SUBSCRIPTION_ROUTES.SUBSCRIBE}`} element={<Subscribe />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          path={`${SUBSCRIPTION_ROUTES.CHECKOUT}/:idProduct/:idPrice`}
          element={
            <Elements stripe={STRIPE_PROMISE}>
              <Checkout />
            </Elements>
          }
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path={`${SUBSCRIPTION_ROUTES.SUCCESS}`} element={<Success />} />
      </Route>
    </Routes>
  );
};

export default SubscriptionRouter;

import { createTheme } from "@material-ui/core/styles";

const Theme = createTheme({
  typography: {
    // fontFamily: 'ProximaNovaA-Regular',
    /*display3: {
      color: '#FFFFFF',
    },
    display2: {
      color: '#FFF',
      fontSize: 40,
      marginBottom: 40,
      fontWeight: 300,
    },
    display4: {
      color: '#FFF',
      fontSize: 33,
      marginBottom: 10,
      fontWeight: 300,
    },*/
    button: {},
  },

  palette: {
    primary: {
      main: "#D77862",
      light: "#B5CED6",
      dark: "#1a1a1a",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FFF",
    },
    error: {
      main: "#f44336",
    },
  },
});

export default Theme;

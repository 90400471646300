import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import AuthRouter from "./Auth";
import { AUTH_ROUTES, ROOT_ROUTES } from "../shared/constants/routes";
import NoMatch from "./NoMatch";
import PrivateRoute from "../shared/components/PrivateRoute";
import SubscriptionRouter from "./Subscription";
import { AuthContext } from "../shared/context/AuthContext";
import { getAccessToken, getLoggedUser, getRefreshToken } from "../shared/utils/localStorage";
import { AUTH_ACTIONS } from "../shared/context/AuthContext/Constants";
import LoginFromApp from "./LoginFromApp";
import Share from "./Share";
import OpenApp from "./OpenApp";

const Root = () => {
  const { dispatch: authDispatch } = useContext(AuthContext);

  useEffect(() => {
    const user = getLoggedUser();
    const token = getAccessToken();
    const refreskToken = getRefreshToken();
    if (user && token && refreskToken) {
      authDispatch({
        type: AUTH_ACTIONS.LOGIN,
        user: user,
        token: token,
        refreskToken: refreskToken,
      });
    } else {
      authDispatch({
        type: AUTH_ACTIONS.LOGOUT,
      });
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path={`/${ROOT_ROUTES.SUBSCRIPTION}/*`} element={<SubscriptionRouter />} />
        </Route>
        <Route path={`/${ROOT_ROUTES.AUTH}/*`} element={<AuthRouter />} />
        <Route path={`/${ROOT_ROUTES.LOGIN_FROM_APP}/:token`} element={<LoginFromApp />} />
        {/* <Route path={`/${ROOT_ROUTES.VERIFY}/:token`} element={VerifyUser} />
        <Route path={`/${ROOT_ROUTES.CHANGE}/:token`} element={ChangePassword} /> */}
        <Route path={`/${ROOT_ROUTES.SHARE}/:codeType/:code`} element={<Share />} />
        <Route path={`/${ROOT_ROUTES.COPY}/:codeType/:code`} element={<Share />} />
        <Route path={`/${ROOT_ROUTES.OPEN_APP}`} element={<OpenApp />} />
        <Route path={``} element={<Navigate to={`/${ROOT_ROUTES.AUTH}/${AUTH_ROUTES.LOGIN}`} />} />
        <Route path={`*`} element={<NoMatch />} />
      </Routes>
    </Router>
  );
};

export default Root;

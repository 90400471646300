import React, { FC } from "react";
import Item from "./Item";
import styled from "styled-components";
import _ from 'lodash';
import { EProductType } from "../../../../shared/enums/stripeProductType.enum";

const Container = styled.ul`
  max-width: 70%;
  margin: 0 auto 48px auto;
  list-style: none;
`;

const HeaderText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 18px;
  text-align: left;
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 24px;
`;

interface IProp {
  type: EProductType;
}

const Checklist: FC<IProp> = ({ type }) => {
  const isPremium = type === EProductType.PREMIUM;
  const storage = isPremium ? "50" : "100";
  const users = isPremium ? "1 user" : "2 users";

  const PREMIUM_TEXT_ITEMS = {
    storage: [
      {highlight: true, text: `${storage}GB in storage`},
      {text: '250MB File Size Per Upload'}
    ],
    boards: [
      {highlight: true, text: 'Premium board color themes'},
      {text: 'Unlimited boards'}
    ],
    items: [
      {highlight: true, text: 'Upload photos and videos'},
      {text: 'Add notes'},
      {text: 'Scan documents'}
    ],
    'other features': [
      {highlight: true, text: 'Full activity history'},
      {highlight: false, text: '0 extra Premium subscriptions'},
      {text: 'Co-manage your household'},
      {text: 'Securely backed up on cloud'}
    ]
  };

  const HOUSEHOLD_TEXT_ITEMS = {
    storage: [
      {highlight: true, text: `${storage}GB in storage`},
      {text: '250MB File Size Per Upload'}
    ],
    boards: [
      {highlight: true, text: 'Premium board color themes'},
      {text: 'Unlimited boards'}
    ],
    items: [
      {highlight: true, text: 'Upload photos and videos'},
      {text: 'Add notes'},
      {text: 'Scan documents'}
    ],
    'other features': [
      {highlight: true, text: 'Full activity history'},
      {highlight: true, text: '1 extra Premium subscription'},
      {text: 'Co-manage your household'},
      {text: 'Securely backed up on cloud'}
    ]
  };

  return (
    <Container>
      {_.map((isPremium ? PREMIUM_TEXT_ITEMS : HOUSEHOLD_TEXT_ITEMS), (value, key) => (
        <>
          <HeaderText>{key}</HeaderText>
          {_.map(value, ({ highlight, text }, index) => (
            <Item key={index} text={text} style={{ fontWeight: !!highlight ? "bold" : "normal" }} />
          ))}
        </>
      ))}
    </Container>
  );
};

export default Checklist;

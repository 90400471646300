import React, { useContext, useEffect, useState } from "react";
import { AuthContainer } from "../../../../components/AuthContainer";
import { CircularProgress, Grid, useTheme } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {
  AUTH_ROUTES,
  ROOT_ROUTES,
  SUBSCRIPTION_ROUTES,
} from "../../../../../../shared/constants/routes";
import styled from "styled-components";
import { Underline } from "../../../../components/Form/Underline";
import Button from "../../../../../../shared/components/Button";
import { AuthContext } from "../../../../../../shared/context/AuthContext";
import { AUTH_ACTIONS } from "../../../../../../shared/context/AuthContext/Constants";
import { useLazyQuery, useMutation } from "@apollo/client";
import LOGIN from "../../../../../../graphql/auth/login.query";
import { ILoginResponse } from "../../../../../../graphql/auth/interfaces/loginResponse.interface";
import { ILoginRequest } from "../../../../../../graphql/auth/interfaces/loginRequest.interface";
import {
  IValidateCodeRequest,
  IValidateCodeResponse,
} from "../../../../../../graphql/auth/interfaces/validateCode.interface";
import { VALIDATE_CODE } from "../../../../../../graphql/auth/validateCode.mutation";
import { GENERATE_CODE } from "../../../../../../graphql/auth/generateCode.mutation";
import {
  ICodeRequest,
  ICodeResponse,
} from "../../../../../../graphql/auth/interfaces/generateCode";
import { ECodeType } from "../../../../../../graphql/auth/interfaces/codeType.enum";
import { get } from "lodash";
import { VALIDATE_PHONE_NUMBER } from "../../../../../../graphql/auth/validatePhone.query";
import {
  ICheckPhoneNumberRequest,
  ICheckPhoneNumberResponse,
} from "../../../../../../graphql/auth/interfaces/validatePhone.interface";
import Logo from "../../../../../../assets/images/logopixie.png";
import Input from "../../../../../Subscription/pages/Checkout/components/Input";
import PhoneInput from "../../../../../../shared/components/PhoneInput";

const Footer = styled.p`
  font-weight: bold;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 120px;
  margin: 20px auto 20px auto;
`;

const Resend = styled.p`
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin: 0;
  margin-top: 15px;
`;

const GRID_STYLES = { width: "100%", margin: "0 auto" };

const MessageError = styled.p`
  font-size: 18px;
  color: #f44336;
  margin: 0 auto;
  margin-bottom: 10px;
`;

const BottomForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [code, setCode] = useState<boolean>(false);
  const [phoneInput, setPhoneInput] = useState<string>("");
  const [codeInput, setCodeInput] = useState<string>("");
  const { dispatch: authDispatch } = useContext(AuthContext);

  const [Login, { data, loading: loadingLogin, error: errorLogin }] = useMutation<
    ILoginResponse,
    ILoginRequest
  >(LOGIN);

  const [GenerateCode, { data: dataCode, loading: loadingCode, error: errorCode }] = useMutation<
    ICodeResponse,
    ICodeRequest
  >(GENERATE_CODE, {
    fetchPolicy: "network-only",
  });

  const [validateCode, { data: valData, loading: valLoading, error: valError }] = useMutation<
    IValidateCodeResponse,
    IValidateCodeRequest
  >(VALIDATE_CODE, {
    fetchPolicy: "network-only",
  });

  const [validatePhone, { data: valPhone, error: valPhoneError, loading: valPhoneLoading }] =
    useLazyQuery<ICheckPhoneNumberResponse, ICheckPhoneNumberRequest>(VALIDATE_PHONE_NUMBER, {
      fetchPolicy: "network-only",
    });

  const loading = loadingLogin || loadingCode || valLoading || valPhoneLoading;

  const _goToRegister = () => {
    navigate(`/${AUTH_ROUTES.BASE}/${AUTH_ROUTES.REGISTER}`);
  };

  const validForm = (): boolean => {
    let valid = false;

    if (!code && phoneInput.length === 10) {
      valid = true;
    } else if (code && codeInput.length === 6) {
      valid = true;
    }
    return valid;
  };

  const generateCode = () => {
    GenerateCode({ variables: { input: { phone: `+1${phoneInput}`, type: ECodeType.LOGIN } } });
  };

  const onSubmit = async () => {
    setError(null);

    if (!code) {
      validatePhone({ variables: { type: ECodeType.LOGIN, phone: `+1${phoneInput}` } });
    } else if (code) {
      validateCode({ variables: { input: { phone: `+1${phoneInput}`, code: codeInput } } });
    }
  };

  useEffect(() => {
    if (dataCode) {
      console.log("setCode");
      setCode(true);
    }
  }, [dataCode]);

  useEffect(() => {
    if (valPhone) {
      const valid = get(valPhone, "CheckPhoneNumber.valid", null);

      valid
        ? generateCode()
        : setError(
            "This number isn't tied to an account yet. Sign up instead or try another number."
          );
    }
  }, [valPhone]);

  useEffect(() => {
    if (valData) {
      const valid = get(valData, "validateCode.valid", null);

      valid
        ? Login({ variables: { input: { phone: `+1${phoneInput}`, code: codeInput } } })
        : setError("Invalid Code!");
    }
  }, [valData]);

  useEffect(() => {
    if (data) {
      authDispatch({
        type: AUTH_ACTIONS.LOGIN,
        user: data.Login.user,
        token: data.Login.access_token,
        refreshToken: data.Login.refresh_token,
      });
      navigate(`/${ROOT_ROUTES.SUBSCRIPTION}/${SUBSCRIPTION_ROUTES.SUBSCRIBE}`);
    }
  }, [data]);

  useEffect(() => {
    if (errorLogin) {
      setError(errorLogin.message || "Wrong credentials");
    }
  }, [errorLogin]);

  return (
    <AuthContainer style={{ backgroundColor: theme.palette.primary.main }}>
      <Image src={Logo} />
      {error && <MessageError>{error}</MessageError>}

      <Grid item xs={12} md={4} style={{ width: "100%", margin: "0 auto 10px auto" }}>
        {loading && <CircularProgress color={"secondary"} />}
      </Grid>

      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Grid container spacing={2} justify="center" alignItems="stretch" direction={"column"}>
          {!code && (
            <Grid item xs={12} md={4} style={GRID_STYLES}>
              <PhoneInput
                style={{ width: "96%" }}
                name={"phone"}
                type={"number"}
                onChange={(e: any) => {
                  setPhoneInput(`${e.target.value.toString()}`);
                }}
                value={phoneInput}
                placeholder={"PHONE NUMBER"}
              />
            </Grid>
          )}

          {code && (
            <Grid item xs={12} md={4} style={GRID_STYLES}>
              <Input
                style={{ width: "97%" }}
                name={"code"}
                type={"number"}
                onChange={e => setCodeInput(e.target.value.toString())}
                value={codeInput}
                placeholder={"CODE"}
              />
              <Resend onClick={() => generateCode()}>Resend code</Resend>
            </Grid>
          )}

          <Grid item xs={12} md={4} style={GRID_STYLES}>
            <Button type="submit" disabled={!validForm() || loading} style={{ maxWidth: "100%" }}>
              {code ? "LOGIN" : "SEND CODE"}
            </Button>
          </Grid>
        </Grid>

        <Footer>
          Don't have an account? <Underline onClick={_goToRegister}>REGISTER</Underline>
        </Footer>
      </form>
    </AuthContainer>
  );
};

export default BottomForm;

import React from "react";
import Top from "../../components/Top";
import BottomForm from "./components/BottomForm";

const Login = () => {
  return (
    <div>
      <Top />
      <BottomForm />
    </div>
  );
};

export default Login;

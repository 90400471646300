import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styled from "styled-components";
import PhoneWithText from "./PhoneWithText";
import Logo from "../../../../assets/images/icon.png";
import photo1 from "../../../../assets/images/pixie-1.png";
import photo2 from "../../../../assets/images/pixie-2.png";
import photo3 from "../../../../assets/images/pixie-3.png";
import photo4 from "../../../../assets/images/pixie-4.png";

const Image = styled.img`
  max-width: 70px;
  margin: 0 auto;
`;

const settings = {
  dots: true,
  infinite: true,
  arrow: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
};

const Container = styled.div`
  min-height: 40vh;
  padding: 16px 0 24px 0;
  max-width: 87%;
  margin: 16px auto;
  text-align: center;
`;

const Top = () => {
  return (
    <Container>
      <Image src={Logo} />
      <Slider {...settings} autoplay={true} autoplaySpeed={4000}>
        <PhoneWithText
          title={"Enjoy your memories more."}
          subtitle={"A simple way to store, organize, and share your photos and videos."}
          image={photo1}
        />
        <PhoneWithText
          title={"Create videos to share & enjoy"}
          subtitle={"Combine your photos and videos into compilation videos in a few simple steps!"}
          image={photo2}
        />
        <PhoneWithText
          title={"Organize your memories"}
          subtitle={
            "See all of your memories on your storyline, and easily turn them into albums and videos."
          }
          image={photo3}
        />
        <PhoneWithText
          title={"Tell your own unique story"}
          subtitle={
            "Our memories are precious. Record your experiences and keep them private, or share with others!"
          }
          image={photo4}
        />
      </Slider>
    </Container>
  );
};

export default Top;

export const AUTH_ACTIONS = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  SET_TOKEN: "SET_TOKEN",
  SET_LOADING: "SET_LOADING",
  SET_ASSETS_LOADING: "SET_ASSETS_LOADING",
  SET_USER: "SET_USER",
};

export enum EActionsType {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  SET_TOKEN = "SET_TOKEN",
  SET_LOADING = "SET_LOADING",
  SET_ASSETS_LOADING = "SET_ASSETS_LOADING",
  SET_USER = "SET_USER",
}

export const STORAGE_KEYS = {
  ACCESS_TOKEN: "token",
  REFRESH_TOKEN: "refreshToken",
  USER: "user",
};

import { gql } from "@apollo/client";
import UserFragment from "../fragments/User.fragment";

export const GET_USER = gql`
  query user($input: GetUserInput!) {
    user(input: $input) {
      ...User
    }
  }
  ${UserFragment.fragment}
`;

export default GET_USER;

import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { CircularProgress, Grid } from "@material-ui/core";
import Input from "./Input";
import { useLazyQuery } from "@apollo/client";
import GET_USER from "../../../../../graphql/users/getUser.query";
import { IUser } from "../../../../../shared/interfaces/User";
import {
  IGetUserRequest,
  IGetUserResponse,
} from "../../../../../graphql/users/interfaces/getUser.interface";
import { useFormik } from "formik";
import * as yup from "yup";
import PhoneInput from "../../../../../shared/components/PhoneInput";

const validateSchema = yup.object().shape({
  email: yup.string().min(1).email().required("Required"),
  firstName: yup.string().min(1).required("Required"),
  lastName: yup.string().min(1).required("Required"),
});

const Text = styled.p`
  font-size: 16px;
`;

const Label = styled.p`
  font-size: 13px;
`;

const TextError = styled.p`
  font-size: 12px;
  color: #fa755a;
  margin: 0;
`;

export interface INewFriend {
  id?: string;
  phone: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface IProps {
  onFriendSelecetd: (friend: INewFriend | null) => void;
  inputPhoneWithData: (value: boolean) => void;
}

const FriendInput: FC<IProps> = ({ onFriendSelecetd, inputPhoneWithData }) => {
  const [phoneInput, setPhoneInput] = useState<string>("");
  const [friend, setFriend] = useState<IUser | null>(null);
  const [noFriend, setNoFriend] = useState<boolean>(false);
  const [newFriend, setNewFriend] = useState<INewFriend | null>(null);

  const [getUser, { data, loading, error }] = useLazyQuery<IGetUserResponse, IGetUserRequest>(
    GET_USER,
    { fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }
  );

  const fetchUser = () => {
    if (phoneInput) {
      getUser({ variables: { input: { phone: `+1${phoneInput}` } } });
    }
  };

  const validPhoneInput = (): boolean => {
    return phoneInput.length > 0 && phoneInput.length < 10 ? true : false;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    onSubmit: () => {},
    validationSchema: validateSchema,
    validateOnMount: true,
  });

  useEffect(() => {
    onFriendSelecetd(newFriend);
  }, [newFriend]);

  useEffect(() => {
    if (phoneInput?.length === 10) {
      fetchUser();
    } else {
      setFriend(null);
      setNewFriend(null);
      setNoFriend(false);
      formik.resetForm();
    }

    phoneInput.length > 0 ? inputPhoneWithData(true) : inputPhoneWithData(false);
  }, [phoneInput]);

  useEffect(() => {
    if (data) {
      const user = data.user;
      if (user) {
        setFriend(user);
        setNewFriend({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
        });
        setNoFriend(false);
      } else {
        setNoFriend(true);
        setFriend(null);
      }
    }
  }, [data]);

  useEffect(() => {
    if (noFriend && formik.isValid && phoneInput) {
      setNewFriend({
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        phone: phoneInput,
      });
    } else {
      setNewFriend(null);
    }
  }, [formik.values, formik.isValid]);

  return (
    <>
      <Grid item xs={12}>
        <Label>
          With your Household subscription, an aditional member can be added within your
          subscription. Add your Household member's information.
        </Label>
      </Grid>
      <Grid item xs={12}>
        <PhoneInput
          style={{ width: "98%" }}
          disabled={loading}
          onChange={(e: any) => setPhoneInput(e.target.value)}
          value={phoneInput}
          placeholder={"Household member's phone number"}
        />
      </Grid>

      {validPhoneInput() && (
        <Grid item xs={12}>
          <TextError>Invalid phone number</TextError>
        </Grid>
      )}

      {loading && (
        <Grid item xs={12} style={{ marginBottom: "20px" }}>
          <CircularProgress color={"primary"} />
        </Grid>
      )}

      {noFriend && (
        <>
          <Grid item xs={12}>
            <Label>
              We didn't find an existing user with that phone number. Add the rest of their
              information below to invite them.
            </Label>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Input
              disabled={loading}
              name={"firstName"}
              onChange={formik.handleChange}
              value={formik.values.firstName}
              placeholder={"Household member's first name"}
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Input
              disabled={loading}
              name={"lastName"}
              onChange={formik.handleChange}
              value={formik.values.lastName}
              placeholder={"Household member's last name"}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              disabled={loading}
              name={"email"}
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder={"Household member's email address"}
            />
          </Grid>
        </>
      )}

      {friend && (
        <>
          <Grid item xs={12}>
            <Text>Name: {`${friend.firstName} ${friend.lastName}`}</Text>
          </Grid>
          <Grid item xs={12}>
            <Text>Email: {`${friend.email}`}</Text>
          </Grid>
          <Grid item xs={12}>
            <Text>Phone number: {`${friend.phone}`}</Text>
          </Grid>
        </>
      )}
    </>
  );
};

export default FriendInput;

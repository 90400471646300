export const isAnulPrice = (value: string | undefined): boolean | undefined => {
  if (value) {
    const val = parseInt(value.slice(0, value?.length - 2));
    return val > 20 ? true : false;
  }
};

export const formatPrice = (value: string | undefined): string | undefined => {
  if (value) {
    return `$${value.slice(0, value.length - 2)}.${value.slice(value.length - 2)}`;
  }
};

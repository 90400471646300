import styled from "styled-components";
import { useTheme } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../../shared/context/AuthContext";
import { AUTH_ACTIONS } from "../../../../shared/context/AuthContext/Constants";

const Container = styled.div`
  color: #ffffff;
  padding: 7px;
  display: flex;
  justify-content: space-between;
`;

const EmptyText = styled.p`
  margin: 0;
  width: 60px;
`;

const Logout = styled.button`
  background-color: transparent;
  color: #fff;
  outline: none;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
`;

const Text = styled.p`
  font-size: 26px;
  margin: 0;
  font-weight: lighter;
`;

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { dispatch: authDispatch } = useContext(AuthContext);

  const handleLogout = () => {
    authDispatch({
      type: AUTH_ACTIONS.LOGOUT,
    });
    navigate(`/`);
  };

  return (
    <Container style={{ backgroundColor: theme.palette.primary.main }}>
      <EmptyText />
      <Text>P I X I E</Text>
      <Logout onClick={handleLogout}>Log out</Logout>
    </Container>
  );
};

export default Header;

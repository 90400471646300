import styled from "styled-components";

const Input = styled.input`
  border: 2px solid #d3d3d3;
  padding: 8px 0;
  text-indent: 16px;
  width: 100%;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: rgba(26, 26, 26, 0.5);
  }

  :focus {
    outline: none;
    border: 2px solid #d3d3d3;
  }
`;

export default Input;

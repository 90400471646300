export const ROOT_ROUTES = {
  AUTH: "auth",
  SUBSCRIPTION: "subscription",
  VERIFY: "verify",
  CHANGE: "change-password",
  LOGIN_FROM_APP: "login-from-app",
  SHARE: "share",
  COPY: "copy",
  OPEN_APP: "open",
};

export const AUTH_ROUTES = {
  BASE: "auth",
  LOGIN: "login",
  REGISTER: "register",
};

export const SUBSCRIPTION_ROUTES = {
  BASE: "subscription",
  SUBSCRIBE: "subscribe",
  CHECKOUT: "checkout",
  SUCCESS: "success",
};

import { StyledProps } from "@material-ui/styles";
import styled, { CSSProperties } from "styled-components";
import Check from "../../../../assets/images/check.svg";

const Image = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 5px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
`;

interface IProps {
  text: string;
  style: CSSProperties;
}

const Item = (props: IProps) => {
  const { text, style } = props;
  return (
    <Container>
      <Image src={Check} />
      <Text style={style}>{text}</Text>
    </Container>
  );
};

export default Item;

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { onError } from "@apollo/link-error";
import { fromPromise } from "apollo-link";
import { get } from "lodash";
import { EXCHANGE_REFRESH_TOKEN } from "../graphql/auth/refreshToken.query";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  setUser,
} from "../shared/utils/localStorage";
//LIBS & CONSTANTS

const REACT_APP_API_URL = process.env[`REACT_APP_API_URL`];
const REACT_APP_GRAPH_ENDPOINT = process.env[`REACT_APP_GRAPH_ENDPOINT`];

let isRefreshing = false;

let apolloClient: any;

let pendingRequests: any[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const httpLink = createHttpLink({ uri: `${REACT_APP_API_URL}/${REACT_APP_GRAPH_ENDPOINT}` });

const getNewToken = async () => {
  const refreshToken = getRefreshToken();
  return apolloClient
    .query({
      query: EXCHANGE_REFRESH_TOKEN,
      variables: { input: { refreshToken } },
    })
    .then((response: any) => {
      const tempToken = get(response, "data.ExchangeRefreshToken.access_token", null);
      if (!tempToken) {
        return null;
      }
      const user = get(response, "data.ExchangeRefreshToken.user", null);
      const refresh_token = get(response, "data.ExchangeRefreshToken.refresh_token", null);
      setUser(user);
      setRefreshToken(refresh_token);
      setAccessToken(tempToken);
      return tempToken;
    })
    .catch((err: any) => {
      console.log(`[Network error]: ${err.message || "Error refreshing token!"}`);
    });
};

const authLink = setContext(async (_, { headers }) => {
  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      apiVersion: "1.1",
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

/**
 * response: response from the server
 * graphQLErrors: GraphQLErrors
 * networkError: network Error
 */
const error = onError(({ graphQLErrors, networkError, operation, response, forward }): any => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    for (const error of graphQLErrors) {
      if (
        error.extensions &&
        error.extensions.code &&
        error.extensions.code === "UNAUTHENTICATED"
      ) {
        let forward$;

        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            getNewToken()
              .then(accessToken => {
                // Store the new tokens for your auth link
                resolvePendingRequests();
                return accessToken;
              })
              .catch(error => {
                pendingRequests = [];
                // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                return;
              })
              .finally(() => {
                isRefreshing = false;
              })
          ).filter(value => Boolean(value));
        } else {
          // Will only emit once the Promise is resolved
          forward$ = fromPromise(
            new Promise(resolve => {
              //@ts-ignore
              pendingRequests.push(() => resolve());
            })
          );
        }

        return forward$.flatMap(() => forward(operation));
      } else if (error.message) {
        console.log(`[GraphQL error]: Message: ${error.message}`);
      }
    }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const cache = new InMemoryCache({});

apolloClient = new ApolloClient({
  link: error.concat(authLink.concat(httpLink)),
  cache: cache,
  defaultOptions: {
    mutate: { errorPolicy: "all" },
  },
});

export default apolloClient;

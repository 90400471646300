import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Logo from "../../../../assets/images/icon.png";
import { Button as MuiButton, CircularProgress, Grid, Theme, withStyles } from "@material-ui/core";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../shared/context/AuthContext";
import { IUser } from "../../../../shared/interfaces/User";
import CardTier, { ISelectedIds } from "../../components/Card";
import { IStripeProduct } from "../../../../shared/interfaces/StripeProduct";
import { useLazyQuery } from "@apollo/client";
import GET_PRODUCTS from "../../../../graphql/product/getProducts.query";
import {
  IGetProductsRequest,
  IGetProductsResponse,
} from "../../../../graphql/product/interfaces/products.interface";
import { ROOT_ROUTES, SUBSCRIPTION_ROUTES } from "../../../../shared/constants/routes";

const Image = styled.img`
  width: 75px;
`;

const Container = styled.div`
  max-width: 80%;
  margin: 0 auto 50px auto;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  margin: 0 auto;
`;

const Button = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: "#4eafd1",
    fontWeight: "bold",
    color: "white",
    width: "65%",
    "&:hover": {
      backgroundColor: "#4eafd1",
    },
    "&:disabled": {
      backgroundColor: "#039BE5",
    },
  },
}))(MuiButton);

const Subscribe = () => {
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const [user, setUser] = useState<IUser | null>(null);
  const [idsSelected, setIdsSelected] = useState<ISelectedIds | null>(null);
  const [stripeProducts, setStripeProducts] = useState<IStripeProduct[]>([]);

  const [getStripeProducts, { data, loading, error }] = useLazyQuery<
    IGetProductsResponse,
    IGetProductsRequest
  >(GET_PRODUCTS);

  const _goToCheckout = () => {
    if (idsSelected) {
      navigate(
        `/${ROOT_ROUTES.SUBSCRIPTION}/${SUBSCRIPTION_ROUTES.CHECKOUT}/${idsSelected.productIdSelected}/${idsSelected.priceIdSelected}`
      );
    }
  };

  useEffect(() => {
    if (state && state.user) {
      setUser(state.user);
    }
  }, [state]);

  useEffect(() => {
    getStripeProducts();
  }, []);

  useEffect(() => {
    if (data) {
      console.log('data:', data);
      setStripeProducts(data.getStripeProducts);
    }
  }, [data]);

  return (
    <div>
      <Header />
      <Container>
        <Grid
          container
          style={{ marginTop: 16 }}
          alignItems={"center"}
          alignContent={"center"}
          spacing={2}
        >
          <Grid item xs={12} justify={"center"} style={{ textAlign: "center" }}>
            <Image src={Logo} />
          </Grid>
          <Grid item xs={12} justify={"center"}>
            <Title>
              {`Hey, ${user?.firstName} ${user?.lastName}, `}choose a plan that's right for you.
            </Title>
          </Grid>

          {loading && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <CircularProgress color={"primary"} />
            </Grid>
          )}

          <Grid
            container
            alignItems={"center"}
            alignContent={"center"}
            spacing={2}
            style={{ marginTop: 20 }}
          >
            {stripeProducts.map((stripeProduct: IStripeProduct) => {
              return (
                <CardTier
                  stripeProduct={stripeProduct}
                  onSelectPrice={price => setIdsSelected(price)}
                  priceSelectedId={idsSelected?.priceIdSelected || null}
                />
              );
            })}
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center", marginTop: "50px" }}>
            <Button
              disabled={!idsSelected}
              onClick={() => {
                _goToCheckout();
              }}
            >
              CONTINUE
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Subscribe;

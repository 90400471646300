import { Context, createContext } from "react";
import { IUser } from "../../interfaces/User";

export interface IAuthContextState {
  isLoading: boolean;
  token: string | null;
  refreshToken: string | null;
  user: IUser | null;
}

const initialState: IAuthContextState = {
  isLoading: true,
  token: null,
  refreshToken: null,
  user: null,
};

const AuthContext: Context<{ state: IAuthContextState; dispatch: any }> = createContext({
  state: initialState,
  dispatch: () => {},
});

export default AuthContext;

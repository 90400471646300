import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, useTheme } from "@material-ui/core";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { IS_VALID_WEB_TOKEN } from "../../graphql/auth/isValidWebToken.mutation";
import {
  IValidWebTokenRequest,
  IValidWebTokenResponse,
} from "../../graphql/auth/interfaces/isValidWebToken.interface";
import { AuthContext } from "../../shared/context/AuthContext";
import { AUTH_ACTIONS } from "../../shared/context/AuthContext/Constants";
import { ROOT_ROUTES, SUBSCRIPTION_ROUTES } from "../../shared/constants/routes";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15%;
`;

const Error = styled.p`
  font-size: 26px;
  color: ${({ color }) => color};
`;

const LoginFromApp = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const { dispatch: authDispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>();
  const [error, setError] = useState<boolean>(false);

  const [isValid, { data, loading: loadingToken, error: errorToken }] = useMutation<
    IValidWebTokenResponse,
    IValidWebTokenRequest
  >(IS_VALID_WEB_TOKEN);

  useEffect(() => {
    if (data) {
      const response = data.IsValidWebToken;
      if (response) {
        authDispatch({
          type: AUTH_ACTIONS.LOGIN,
          user: response.user,
          token: response.access_token,
          refreshToken: response.refresh_token,
        });
        navigate(`/${ROOT_ROUTES.SUBSCRIPTION}/${SUBSCRIPTION_ROUTES.SUBSCRIBE}`);
      }
    }
  }, [data]);

  useEffect(() => {
    if (errorToken) {
      setLoading(false);
      setError(true);
    }
  }, [errorToken]);

  useEffect(() => {
    if (params && params.token) {
      setToken(params.token);
    }
  }, [params]);

  useEffect(() => {
    if (token) {
      isValid({ variables: { input: { token: token } } });
    }
  }, [token]);

  return (
    <Container>
      {error && <Error color={theme.palette.error.main}>Invalid token!</Error>}
      {loading && <CircularProgress color={"primary"} />}
    </Container>
  );
};

export default LoginFromApp;

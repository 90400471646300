import { gql } from "@apollo/client";
import UserFragment from "../fragments/User.fragment";

export const REGISTER = gql`
  mutation Registration($input: RegistrationInput!) {
    Registration(input: $input) {
      access_token
      refresh_token
      user {
        ...User
      }
    }
  }
  ${UserFragment.fragment}
`;

import { gql } from "@apollo/client";

const DiscountCodeAppliesToFragment = {
  fragment: gql`
    fragment DiscountCodeAppliesTo on DiscountCodeAppliesTo {
      products
    }
  `,
};

const DiscountCodeFragment = {
  fragment: gql`
    fragment DiscountCode on DiscountCode {
      id
      object
      amount_off
      created
      currency
      duration
      duration_in_months
      livemode
      max_redemptions
      name
      redeem_by
      percent_off
      times_redeemed
      valid
    }
  `,
};
export default DiscountCodeFragment;

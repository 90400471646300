import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Grid, useTheme } from "@material-ui/core";
import Checklist from "../Checklist";
import Product from "../Product";
import { IStripeProduct } from "../../../../shared/interfaces/StripeProduct";
import { IStripePrice } from "../../../../shared/interfaces/StripePrice";
import { formatPrice, isAnulPrice } from "../../../../shared/utils/prices";
import { EProductType } from "../../../../shared/enums/stripeProductType.enum";

const Title = styled.p`
  font-weight: bold;
  font-size: 34px;
  text-align: center;
  max-width: 50%;
  margin: 0 auto 10px auto;
`;

const SubscriptionTerms = styled.p`
  font-size: 12px;
  text-align: center;
`;

export interface ISelectedIds {
  productIdSelected: string;
  priceIdSelected: string;
}

interface IProps {
  stripeProduct: IStripeProduct;
  priceSelectedId: string | null;
  onSelectPrice: (value: ISelectedIds) => void;
}

const CardTier: FC<IProps> = ({ stripeProduct, priceSelectedId, onSelectPrice }) => {
  const theme = useTheme();
  const [anualPrice, setAnualPrice] = useState<string | undefined>("");
  const [monthlyPrice, setMonthlyPrice] = useState<string | undefined>("");

  useEffect(() => {
    if (stripeProduct) {
      stripeProduct.prices.forEach(price => {
        isAnulPrice(price.unit_amount.toString())
          ? setAnualPrice(formatPrice(price.unit_amount.toString()))
          : setMonthlyPrice(formatPrice(price.unit_amount.toString()));
      });
    }
  }, [stripeProduct]);

  return (
    <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
      <Grid item xs={12} justify={"center"}>
        <Title>{stripeProduct.product.name === 'Premium' ? 'Personal' : stripeProduct.product.name}</Title>
      </Grid>
      <Grid item xs={12}>
        <Checklist type={stripeProduct.product.name} />
      </Grid>

      {stripeProduct.prices.map((price: IStripePrice) => {
        return (
          <Grid item xs={12}>
            <Product
              onSelectItem={() =>
                onSelectPrice({
                  productIdSelected: stripeProduct.product.id,
                  priceIdSelected: price.id,
                })
              }
              price={price}
              selected={priceSelectedId ? priceSelectedId === price.id : false}
            />
          </Grid>
        );
      })}

      <Grid item xs={12} style={{ textAlign: "center" }}>
        <SubscriptionTerms>
          Subscription terms: Annual subscription is {anualPrice} USD and automatically <br />
          renews each year. <br />
          Monthly subscription is {monthlyPrice} USD and automatically renews each month.
        </SubscriptionTerms>
      </Grid>
    </Grid>
  );
};

export default CardTier;

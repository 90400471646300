import React from "react";
import Header from "../../components/Header";
import Logo from "../../../../assets/images/icon.png";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import AndroidLogo from "../../../../assets/images/android-logo.png";
import IosLogo from "../../../../assets/images/ios-logo.png";
import { Link } from "react-router-dom";

const AndroidImage = styled.img`
  max-width: 180px;
`;

const IosImage = styled.img`
  max-width: 180;
  height: 105px;
`;

const Image = styled.img`
  width: 90px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
`;

const Subtitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
`;

const TakeText = styled.a`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
  text-decoration: underline;
  text-decoration-color: #d77862;
  text-decoration-thickness: 3px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 20%;
  margin: 0 auto;
  align-items: center;
`;

const DEEP_LINK = process.env.REACT_APP_DEEP_LINK;

const Success = () => {
  return (
    <div>
      <Header />
      <Grid container style={{ marginTop: 16 }} alignItems={"center"} alignContent={"center"}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Image src={Logo} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Title>THANK YOU!</Title>
          <Subtitle>You are all signed up!</Subtitle>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <TakeText href={DEEP_LINK}>TAKE ME TO THE APP</TakeText>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Row>
            <AndroidImage src={AndroidLogo} />
            <IosImage src={IosLogo} />
            {/* <a href={""} target={"_blank"}>
            </a>
            <a href={""} target={"_blank"}>
            </a> */}
          </Row>
        </Grid>
      </Grid>
    </div>
  );
};

export default Success;

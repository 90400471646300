import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15%;
`;

const SubContainer = styled.div``;

const LinkCustom = styled.a`
  display: block;
  text-align: center;
  font-weight: 500;
  padding: 10px;
`;

const Text = styled.p`
  font-size: 18px;
  text-align: center;
`;

const DEEP_LINK = process.env.REACT_APP_DEEP_LINK;

const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return "Android"
  }
  else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return "iOS"
  }
  return "Other"
}

// Plans for this components:
//
// 1. Make redirecting to the deep link super speedy
// 2. Show some more context.
//    • <firstName> has shared a <itemType> called <itemTitle> with you!
//    • You will be redirected to Pixie shortly.
//    • If you don't yet have Pixie, download it <here> and create an account.
// 3. Loading spinner in case it really does take a second

const Share = () => {
  const theme = useTheme();
  const params = useParams();
  const location = useLocation();
  const isCopyRoute = location.pathname.indexOf('copy') > -1;
  const [codeType, setCodeType] = useState<string>();
  const [code, setCode] = useState<string>();
  const [clickedLink, setClickedLink] = useState<boolean>(false);

  const appleWebLink = 'https://apps.apple.com/us/app/pixie-life-organized/id1594733115';
  const playStoreWebLink = 'https://play.google.com/store/apps/details?id=pixie.board.app&hl=en_US&gl=US';
  const OS = getMobileOS();
  const onMobile = (OS === 'Android' || OS === 'iOS');
  const isIos = (OS === 'iOS');

  const LINK = `${DEEP_LINK}${isCopyRoute ? 'copy/' : ''}${codeType}?code=${code}`;
  console.log('LINK:', LINK);

  useEffect(() => {
    if (params && params.codeType && params.code) {
      console.log('params:', params);
      setCode(params.code);
      setCodeType(params.codeType);
    }
  }, [params]);

  useEffect(() => {
    if (code && codeType) {
      if (!clickedLink) {
        window.location.href = LINK;
      }
    }
  }, [code, codeType]);

  return (
    <Container>
      <SubContainer>
        {onMobile ? (
          <>
            <Text>You're being redirected to the Pixie app.</Text>
            <LinkCustom
              onClick={() => setClickedLink(true)}
              style={{ color: theme.palette.primary.main }}
              href={isIos ? appleWebLink : playStoreWebLink}
            >
              Don't have the app? Click here to download.
            </LinkCustom>
          </>
        ) : (
          <>
            <Text>Please visit this link from your phone:</Text>
            <LinkCustom style={{ color: theme.palette.primary.main }} onClick={() => navigator.clipboard.writeText(`${window.location.href}`)}>{window.location.href}</LinkCustom>
          </>
        )}
      </SubContainer>
    </Container>
  );
};

export default Share;

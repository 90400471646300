import { gql } from "@apollo/client";
import StripePriceFragment from "../fragments/StripePrice.fragment";
import StripeProductFragment from "../fragments/StripeProduct.fragment";

export const GET_PRODUCT_ID = gql`
  query getStripeProductById($input: GetStripeProductByIdInput!) {
    getStripeProductById(input: $input) {
      product {
        ...StripeProduct
      }
      prices {
        ...StripePrice
      }
    }
  }
  ${StripeProductFragment.fragment}
  ${StripePriceFragment.fragment}
`;

export default GET_PRODUCT_ID;

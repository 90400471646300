import { gql } from "@apollo/client";
import UserFragment from "../fragments/User.fragment";

export const EXCHANGE_REFRESH_TOKEN = gql`
  query ExchangeRefreshToken($input: ExchangeRefreshTokenInput!) {
    ExchangeRefreshToken(input: $input) {
      access_token
      refresh_token
      user {
        ...User
      }
    }
  }
  ${UserFragment.fragment}
`;
